


























//      <img :src="path+image" style="width:100%;" alt="">
//import { Options, Vue } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';

import NavBar from '@/components/NavBar.vue'; // @ is an alias to /src
import Footer from '@/components/Footer.vue'; // @ is an alias to /src
import axios from 'axios';

import { Carousel, Slide, /*Pagination,*/ Navigation } from 'vue-carousel';
//import 'vue-carousel/dist/carousel.css';




@Component({
components: {
    Footer,
    NavBar,
    Carousel,
    Slide,
    //Pagination,
    Navigation,
  },
})

export default class Home extends Vue {
    public img:Array<String>=[];
    name= 'SEmailSharing';
    public path= "";

    
    data(){
        return {
            img:null,
            path:this.path,
        }
    }

      onClose() {}
      onOpen() {}
      onBlock() {}
      onFocus() {}

    async beforeCreate(){
        //this.path= window.location.toString().slice(0,-5)+"1337";
        this.path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(this.path+'/api/carousels?populate=*', {});
        const imageUrls= data.data.map((a:any)=>a.attributes.pic?.data?.attributes.url);

	const imgs=new Array<any>();
	this.img=new Array<String>();
	for(let i=0;i<imageUrls.length;i++){
		const im=new Image();
		im.src=this.path+imageUrls[i];
		console.error("Path",im.src);
		imgs.push(im);
		im.onload=()=>{
			this.img.push(im.src);
		}
	}

        console.error(imageUrls);
        console.error(data);
        //const imageTags=imageUrls.map((a:any)=>"<img src=\""+local+a+"\">");
        //console.error("TAGS",imageTags);
        //this.img=imageUrls;
    }

}
