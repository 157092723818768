import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,


	     meta: {
	      title: 'Jacco Herhaus | Producer',
	      metaTags: [
		{
		  name:"description",
		  content:"Jacco Herhaus is a producer, musician and sound engineer based in Hannover. He was involved in various Projects with known Artists like ..."
		},
		{
		  name:"keywords",
		  content:"jacco herhaus jaccoherhaus jaccomoaugusto Musiker selbstständig produzent producer musician master Record producer audio engineer hannover freelancer studio"
		},
		{
		  name:"page-topic",
		  content:"Music"
		},
	      ]
	    },



  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),

	     meta: {
	      title: 'Jacco Herhaus | About',
	      metaTags: [
		{
		  name:"description",
		  content:"Learn more about Jacco Herhaus and his stunning music productions. He started producing music in his early ..."
		},
		{
		  name:"keywords",
		  content:"jacco herhaus jaccoherhaus jaccomoaugusto Musiker selbstständig produzent producer musician master Record producer audio engineer hannover freelancer studio"
		},
		{
		  name:"page-topic",
		  content:"Music"
		},
	      ]
	    },


  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),

	     meta: {
	      title: 'Jacco Herhaus | Contact',
	      metaTags: [
		{
		  name:"description",
		  content:"You can contact Jacco Herhaus for more Information about booking him for studio sessions or live acts."
		},
		{
		  name:"keywords",
		  content:"jacco herhaus jaccoherhaus jaccomoaugusto Musiker selbstständig produzent producer musician master Record producer audio engineer hannover freelancer studio"
		},
		{
		  name:"page-topic",
		  content:"Music"
		},
	      ]
	    },


  },
  {
    path: '/privacy',
    name: 'Datenschutz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Datenschutz.vue'),
     meta: {
      title: 'Jacco Herhaus | Datenschutz',
      metaTags: [
	{
	  name:"robots",
	  content:"noindex"
	},
	]
    },
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
	component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue'),
	     meta: {
	      title: 'Jacco Herhaus | Impressum',
	      metaTags: [
		{
		  name:"robots",
		  content:"noindex"
		},
		]
	    },
  },
  {
    path: '/discography',
    name: 'Discography',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
	component: () => import(/* webpackChunkName: "about" */ '../views/Discography.vue'),
	     meta: {
	      title: 'Jacco Herhaus | Discography',
	      metaTags: [
		{
		  name:"description",
		  content:"Over time, Jacco Herhaus produced various Artists from many different genres. Checkout some of his work."
		},
		{
		  name:"keywords",
		  content:"jacco herhaus jaccoherhaus jaccomoaugusto Musiker selbstständig produzent producer musician master Record producer audio engineer hannover freelancer studio"
		},
		{
		  name:"page-topic",
		  content:"Music",
		}
	      ]
	    }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Detail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el?.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
   nearestWithMeta.meta.metaTags.map((tagDef:any) => {
   const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach((tag:any) => document.head.appendChild(tag));

  next();
});



export default router
