








































//import { BNavbar } from 'bootstrap-vue'
//Vue.component('b-navbar', BNavbar)

import { NavbarPlugin } from 'bootstrap-vue'
Vue.use(NavbarPlugin)

import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component'
import axios from 'axios';

@Component({
components: {
}
})

//@Component
export default class NavBar extends Vue {
   public img=null;
    public path= "";

    data(){
        return {
            img:null,
            path:this.path,
            image: "http://localhost:1337/uploads/thumbnail_jacco1_57ad2a8e46.png",
        }
    }

    async created(){
        this.path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(this.path+'/api/logo?populate=*', {});
        this.img=data.data.attributes.logo.data.attributes.url;



        const faviconData = await axios.get(this.path+'/api/favicon?populate=*', {});
	console.error("FAV:", faviconData);
        const icon =faviconData.data.data.attributes.favicon.data.attributes.url;
	const favicon = document.getElementById("favicon");      
	if(favicon!=null)
		(<any>favicon).href = this.path+icon;//"https://www.google.com/favicon.ico";


    }

}


