







































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({
    components: {
      },
})

export default class Footer extends Vue {

    public path= "";
    public img=undefined;
    data(){
        return {
            path:this.path,
            img:this.img,
        }
    }

    async created(){

        this.path = window.location.protocol + "//api." + window.location.hostname; // + "/" + getUrl.pathname.split('/')[1];
        const { data } = await axios.get(this.path+'/api/social-medias?populate=*', {});
        const imageUrls= data.data.map((a:any)=>a.attributes);
        for(let i=0;i<imageUrls.length;i++){
            imageUrls[i]={path: imageUrls[i].pic.data.attributes.url, index:i, link:imageUrls[i].link};
        }
        this.img=imageUrls;
    }
}
